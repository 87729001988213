import React from 'react'
import Layout from "../components/Layout"
import * as styles from '../styles/features.module.css'
import ContentCards from '../components/ContentCards'

export default function Features() {

    return (
        <Layout>

            {/* features cover */}

            <ContentCards>
            <div className={styles.featuresCoverContainer}>
              <div className={styles.heroContent}>
                <p className={styles.heroContentLine1}>Redifine your vending experience with Vendserve Cloud</p>
                <p className={styles.heroContentLine3}>With the most advanced Vending Machine Management Cloud and suite of world-class cashless payment modes, we help you enhance your vending business.</p>
              </div>
              <div className='home-frame'>
                <img src='features-cover.png' className={styles.coverImage} alt='people with vending machine'/>
              </div>
            </div>
            </ContentCards>

            {/* smart vend os and software */}

            <div className='features-smart-vend-head'>
              <span className='features-smart-vend-head-text'>Smart Vend 2.0 powered by</span>
              <span className='features-smart-vend-head-text-mobile'>Smart Vend 2.0<br /><p className='smart-vend-powered-by'>powered by</p> </span>
              <img className='features-smart-vend-head-logo' src='vendserves-logo.png' alt='vendserves logo' />
            </div>

            {/* smart vend os features */}
            <div className='smart-vend-os-feature-wrapper'>
                <div className='smart-vend-os-feature-image-and-caption'>
                    <img className='feature-image' src='pdp.png' alt='kiosk product display page' />
                    <p className='text-green'>Simple User Interface</p>
                </div>
                <div className='smart-vend-os-feature-content'>
                    <ContentCards>
                        <div className='smart-vend-os-feature-content-ul'>
                            <ul>
                                <li>
                                    No need of any app
                                </li>
                                <li>
                                    No complications
                                </li>
                                <li>
                                    Select-Scan-Pay-Vend
                                </li>
                                <li>
                                    Easy to use and maintain
                                </li>
                            </ul>
                        </div>
                    </ContentCards>
                </div>
            </div>
            <div className='smart-vend-os-feature-wrapper'>
                <div className='smart-vend-os-feature-image-and-caption'>
                    <img className='feature-image' src='multiple-payment-options-features.png' alt='multiple payment options' />
                    <p>Multiple Payment Options</p>
                </div>
                <div className='smart-vend-os-feature-content'>
                    <ContentCards>
                        <div className='smart-vend-os-feature-content-ul'>
                            <ul>
                                <li>
                                    Accepts wide range of bank notes with upgraded cash acceptor
                                </li>
                                <li>
                                    Never miss a sale with inbuilt cashless payment methods. Works with all UPI supported apps.
                                </li>
                            </ul>
                        </div>
                    </ContentCards>
                </div>
            </div>
            <div className='smart-vend-os-feature-wrapper'>
                <div className='smart-vend-os-feature-image-and-caption'>
                    <img className='feature-image' src='15-6-touch-screen.png' alt='touch screen' />
                    <p>15.6" Touch Screen</p>
                </div>
                <div className='smart-vend-os-feature-content'>
                    <ContentCards>
                        <div className='smart-vend-os-feature-content-ul'>
                            <ul>
                                <li>
                                    Easy to use
                                </li>
                                <li>
                                    Comes with large touchscreen for ease of access and hassle free usage
                                </li>
                            </ul>
                        </div>
                    </ContentCards>
                </div>
            </div>
            <div className='smart-vend-os-feature-wrapper'>
                <div className='smart-vend-os-feature-image-and-caption'>
                    <img className='feature-image' src='automatic-refund.png' alt='automatic refund' />
                    <p>Automatic Refunds with Notifications</p>
                </div>
                <div className='smart-vend-os-feature-content'>
                    <ContentCards>
                        <div className='smart-vend-os-feature-content-ul'>
                            <ul>
                                <li>
                                    Get instant refunds in case of failed transactions
                                </li>
                                <li>
                                    Refunds supported for cash and cashless payments
                                </li>
                            </ul>
                        </div>
                    </ContentCards>
                </div>
            </div>
            <div className='smart-vend-os-feature-wrapper'>
                <div className='smart-vend-os-feature-image-and-caption'>
                    <img className='feature-image' src='dashboard-illustration.png' alt='dashboard illustration' />
                    <p>Online Dashboard</p>
                </div>
                <div className='smart-vend-os-feature-content'>
                    <ContentCards>
                        <div className='smart-vend-os-feature-content-ul'>
                            <ul>
                                <li>
                                    Get to know high performing machines and products
                                </li>
                                <li>
                                    Control of your vending machiens at your fingertips
                                </li>
                                <li>
                                    Check real-time inventory from our dashboard
                                </li>
                            </ul>
                        </div>
                    </ContentCards>
                </div>
            </div>

            {/* dashboard sample */}

            <div className='dashboard-sample'>
                <p>Real-time inventory Management</p>
                <img className='dashboard-sample-image' src='revenue-details-sample.png' alt='dashboard revenue details page' />
            </div>

            {/* dashboard features */}

            <div className={ styles.dashboardFeaturesCards }>
                <div className={ styles.dashboardFeaturesIndividualCard }>
                    <ContentCards>
                        <div className={ styles.dashboardFeatureContentCardInside }>
                            <p className='green-text'>Inventory Management</p>
                            <p>No need to go to the vending machine to check its inventory. Get all updates on our dashboard and refill only when required.</p>
                        </div>
                    </ContentCards>
                </div>
                <div className={ styles.dashboardFeaturesIndividualCard }>
                    <ContentCards>
                        <div className={ styles.dashboardFeatureContentCardInside }>
                            <p className='green-text'>Real-time tracking</p>
                            <p>Get to know when the machine was last refilled. Secure yourself from theft of products as all the transactions get logged and can be accessed through the dashboard.</p>
                        </div>
                    </ContentCards>
                </div>
                <div className={ styles.dashboardFeaturesIndividualCard }>
                    <ContentCards>
                        <div className={ styles.dashboardFeatureContentCardInside }>
                            <p className='green-text'>Demand Analysis</p>
                            <p>We make it easier for you to manage supply and demand by showing on demand analysis helping refill what customers buy more. Increase your sales and thus, your profit!</p>
                        </div>
                    </ContentCards>
                </div>
            </div>

            {/* final features */}
            <div className='more-is-better-section'>
                <p className='more-is-better-text green-text'>More is Always Better</p>

                <div className='final-features-grid'>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='customisation-final.png' alt='customisation' />
                        <p className='green-text'>Customisation</p>
                        <p>Wide range of customisable options according to your business needs</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='plug-and-play.png' alt='plug and play' />
                        <p className='green-text'>Plug and Play</p>
                        <p>No installation required.</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='branding-and-design.png' alt='branding and design' />
                        <p className='green-text'>Branding and Design</p>
                        <p>Comes with attractive vinyl to make your workplace look a little more happening</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='on-site-maintenance.png' alt='on site maintenance' />
                        <p className='green-text'>On-site Maintenance</p>
                        <p>Quick support for all kinds of services and repairs</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='multiple-payment-options-final-png.png' alt='multiple payment options' />
                        <p className='green-text'>Multiple Payment Options</p>
                        <p>Never miss a sale with cash and cashless payment options</p>
                    </div>
                    <div className='features-grid-elements'>
                        <img className='final-features-grid-image' src='regular-updates.png' alt='regular updates' />
                        <p className='green-text'>Regular Updates</p>
                        <p>Powered by Smart Vend 2.0. Simple interface with regular updates.</p>
                    </div>
                </div>
            </div>

        </Layout>
    )
}
